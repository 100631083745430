/* @media(max-width: 800px) {
    .icon__main {
        width: 1.5rem;
        height: 1.5rem;
        padding: 1rem;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}

@media(min-width: 800px) {
    .icon__main {
        width: 3rem;
        height: 3rem;
        padding: 1rem;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
    }
} */
/* 
@media(min-width: 1080px) { */
.icon__main {
    width: 2rem;
    height: 2rem;
    padding: 2rem;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

/* } */

.icon__facebook {
    background-image: url("../../../assets/icon/facebook.png");
}

.icon__line {
    background-image: url("../../../assets/icon/line.png");
}

.icon__mail {
    background-image: url("../../../assets/icon/mail.png");
}

.icon__telephone {
    background-image: url("../../../assets/icon/telephone.png");
}

.icon__map {
    background-image: url("../../../assets/icon/map.png");
}

.icon__edit {
    background-image: url("../../../assets/icon/edit.png");
}