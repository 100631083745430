.image {
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.qrcode {
    width: 50% !important;
    height: 50% !important;
}

.barcode {
    width: 70% !important;
    height: 60% !important;
}

.img__bank {
    height: 100%;
}

.text__bank {
    width: 98%;
    padding: 1rem;
    box-sizing: initial;
}

.text__bank__sub {
    width: 100%;
    margin-left: 1rem;
}

.img__container {
    width: 20%;
    height: 5rem;
    text-align: center;
    margin: .5rem;
}

.text-gray {
    color: #999;
}

.bank__container {
    margin: 1rem;
    display: flex;
}

.text__bank__container {
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .panel__payment {
        padding: 1rem !important;
    }

    .img__container {
        height: 4rem !important;
    }
}