.btn__main {
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 0.5rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: "Prompt-Regular";
}

.btn__main__next {
  background-color: #ff9600;
  color: white;
  padding: 1rem 3rem;
  border: 0.1rem solid #ff9600;
  box-sizing: border-box;
}

.btn__main__back {
  background-color: white;
  border-color: #ff9600;
  border: 0.1rem solid;
  color: #ff9600;
  padding: 1rem 3rem;
}

.btn__main__submit {
  background-color: #3bb54a;
  color: white;
  border: 0.1rem solid #3bb54a;
  padding: 1rem 3rem;
}

.btn__main_close {
  background-color: white;
  border-color: #001f3f;
  border: 0.1rem solid;
  color: #001f3f;
  padding: 1rem 3rem;
}

.btn__main__choose {
  background-color: #545755;
  border: none;
  color: white;
  padding: 1.2rem 2.8rem;
  margin: auto;
  border-radius: 2.5rem;
}

.btn__graph {
  padding: 4px 2px;
  background-color: #ff9600;
  color: white;
  border: 0.1rem solid #ff9600;
  box-sizing: border-box;
  margin-bottom: 2rem;
  height: 3rem;
  width: 100%;
  text-align: center;
}

.btn__menu__click {
  background-color: white;
  border-bottom: 3px solid #ff9600;
  color: #ff9600;
  width: 100%;
  padding: 1.2rem 2.8rem;
}

.btn__menu__normal {
  background-color: white;
  border-bottom: 3px solid white;
  color: #707070;
  width: 100%;
  padding: 1.2rem 2.8rem;
}

.btn__branch:focus {
  background-color: white;
  border-bottom: 1px solid #ff9600;
  color: #ff9600;
  width: 100%;
  padding: 1.2rem 2.8rem;
}

.btn__branch {
  background-color: #ebedef;
  border-left: 5px solid #ff9600;
  color: black;
  width: 100%;
  padding: 1.2rem 2.8rem;
}

.btn__branch:hover {
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.4);
}

@media (max-width: 550px) {
  .apply {
    height: 4.3rem;
    width: 100%;
  }

  .contact {
    height: 4.3rem;
    width: 100%;
  }

  .barcode {
    width: 100%;
  }
}

@media (min-width: 550px) {
  .apply {
    height: 4.3rem;
    width: 11.334rem;
    float: right;
  }

  .contact {
    height: 4.3rem;
    width: 13rem;
    float: right;
  }

  .barcode {
    width: 50%;
  }
}

#check {
  display: none;
}

.load {
  display: none;
  width: 20px;
  height: 20px;
  border: 5px solid #ffffff;
  border-radius: 100%;
}

.open {
  border-top: 5px solid transparent;
  animation: load-animate infinite linear 1s;
}

.btn-label {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-family: "Prompt-Regular";
  font-size: 1.4rem;
  color: #fffeee;
  cursor: pointer;
}

.btn-text {
  font-family: "Prompt-Regular";
  color: #fffeee;
}

#check:checked ~ .btn-label > .btn-text {
  display: none;
}

#check:checked ~ .btn-label > .load {
  display: inline-block;
}

@keyframes load-animate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
    opacity: 0.35;
  }

  100% {
    transform: rotate(360deg);
  }
}
