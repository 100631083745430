.input {
  width: 100%;
  /* padding-top: 0.5rem; */
}

.input__element {
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  /* appearance: none; */
  width: 100%;
  /* min-width: 96%; */
  height: 3.5rem;
  padding: 0.5rem 0.8rem;
  border: 1px solid #ccc;
  border-radius: 7px;
  box-sizing: border-box;
}
