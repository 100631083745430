.card {
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    transition: 0.3s;
    width: 90%;
    border-radius: 0.8rem;
    margin: auto;
    margin-bottom: 1rem;
    border: 0.1rem solid silver;
    height: 100%;
    position: relative;
}

.card:hover {
    cursor: pointer;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.4);
}

.image__card {
    width: 100%;
    height: 20rem;
    object-fit: cover;
    border-radius: 0.7rem 0.7rem 0 0;
}

.text__card {
    /* display: block; */
    padding: 1.6rem;
    /* font-size: 1.4rem; */
    /* height: 100%; */

}

.text__container {
    padding: 1.6rem;
    /* font-size: 1.4rem; */
    height: 100%;
}

.card__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1rem;
}

.box__card {
    margin-top: 3rem;
}

.box__descrip {
    display: -webkit-box;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
    font-size: 14px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.box__btn {
    /* position: absolute; */
    bottom: 1rem;
    left: 50%;
    /* transform: 'translate(-50%, -50%)', */
}

.MuiContainer-root {
    margin-top: 2rem;
}

.MuiGrid-spacing-xs-3>.MuiGrid-item {
    padding: 1rem 0rem !important;
}

.btn_product {
    width: 50%;
}

@media only screen and (max-width: 1199px) {
    .box__card {
        /* margin-top: 3rem;
        margin-left: 10rem;
        margin-right: 10rem; */
        width: 100%;
        max-width: 114rem;
    }
}

@media only screen and (max-width: 1060px) {
    .card__detail {
        width: 100%;
        margin: 3rem 3rem;
    }
}

@media only screen and (min-width: 1061px) {
    .card__detail {
        width: 100%;
        margin: 3rem 15rem;
    }
}

@media only screen and (max-width: 599px) {
    .card {
        display: flex;
        transition: 0.3s;
        /* width: 3rem; */
        border-radius: 0.8rem;
        margin: auto;
        /* margin-bottom: .3rem; */
        border-style: solid;
        border-width: 0.1rem;
        /* height: 100%; */
        position: relative;
    }

    .text__card {
        display: block;
        padding: 1rem;
        /* font-size: 1.4rem; */
        height: 10rem;
    }

    .image__card {
        padding: auto;
        margin: 1rem 1rem;
        width: 30%;
        height: 80%;
        object-fit: cover;
        border-radius: 0;
    }

    .box__descrip {
        line-height: 1;
        -webkit-line-clamp: 3;
    }

    .MuiGrid-spacing-xs-3>.MuiGrid-item {
        padding: 3px !important;
    }

    .btn_product {
        width: 100% !important;
    }
}

@media only screen and (max-width: 464px) {
    .text__card {
        padding: 1rem;
        font-size: 1rem;
    }
}



@media only screen and (max-width: 320px) {


    .image__card {
        padding: auto;
        margin: 2rem 1rem;
        width: 8rem;
        height: 6rem;
        object-fit: cover;
        border-radius: 0;
    }



}




/* .btn__container {

    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 520px));
    grid-gap: 1rem;
} */



/* @font-face {
    font-family: 'Prompt-Regular';
    src: url('./assets/font/Prompt-Regular.ttf');
} */