@media(max-width: 959px) {
  .width100 {
    width: 60% !important;
  }

  .width100_bank {
    width: 80% !important;
  }
}

@media(max-width: 570px) {
  .width100 {
    width: 80% !important;
  }

  .width100_bank {
    width: 80% !important;
  }
}

@media (max-width: 500px) {
  .width100 {
    width: 95% !important;
  }
}

@media(min-width: 960px) {
  .width100 {
    width: 80% !important;
  }

  .width100_bank {
    width: 80% !important;
  }
}