
@media(min-width: 550px) {
    .steppanel{
        padding: 2rem;
        margin: auto;
        width: 50%;
        border: 1px solid #00000030;
    }
    
    .steppanel1{
        padding: 2rem;
        margin: auto;
        background-color: #FF851B50;
        color: #FF851B;
        width: 50%;
    }
    
    .steppanel2{
        padding: 2rem;
        margin: auto;
        background-color: #FF851B;
        color: white;
        width: 50%;
    }
}

@media(min-width: 800px) {
.steppanel{
    padding: 2rem;
    margin: auto;
    width: 30%;
    border: 1px solid #00000030;
}

.steppanel1{
    padding: 2rem;
    margin: auto;
    background-color: #FF851B50;
    color: #FF851B;
    width: 30%;
}

.steppanel2{
    padding: 2rem;
    margin: auto;
    background-color: #FF851B;
    color: white;
    width: 30%;
}
}



@media(max-width: 550px) {
    .steppanel{
        padding: 2rem;
        margin: auto;
        width: 90%;
        border: 1px solid #00000030;
    }
    
    .steppanel1{
        padding: 2rem;
        margin: auto;
        background-color: #FF851B50;
        color: #FF851B;
        width: 90%;
    }
    
    .steppanel2{
        padding: 2rem;
        margin: auto;
        background-color: #FF851B;
        color: white;
        width: 90%;
    }
}


