.forgot__container {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  /* font-size: 1.6rem; */
}

.forgot__body_submit {
  display: flex;
}

.forgot__header {
  display: flex;
  justify-content: center;
  padding: 1rem;
  color: #ff7700;
}

.forgot__body {
  border-top: 1rem solid #ff9600;
  border-left: 1px solid #d7d7d7;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 5px 10px #e3e3e3;
  width: 36rem;
  margin-bottom: 2rem;
  margin-left: 50%;
  transform: translate(-50%, 0%);
}

.forgot__body > div {
  padding: 1rem;
}

.forgot__body_forgot {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.forgot__body_forgot > a {
  text-decoration: none;
  display: block;
  text-align: center;
  color: black;
  max-width: 50%;
  flex: 0 0 50%;
}

.forgot__body_forgot > .split {
  position: absolute;
  left: 50%;
  border-left: 1px solid #999;
  height: 100%;
  top: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  /* font-size: 1.4rem; */
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #babfc5;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

label {
  display: inline-block;
  /* margin-bottom: .5rem; */
  color: #999;
}

.btn-submit {
  text-align: center;
  background-color: #ff9600;
  border-color: transparent;
  padding: 1rem;
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  /* font-size: 1.6rem; */
}

input[type="text"]:focus,
input[type="password"]:focus {
  border-color: #ff9600;
}

.forgot__footer {
  text-align: center;
  margin: 4.5rem 0;
}

.forgot__footer > a {
  padding-left: 1rem;
  color: #ff7700;
}
