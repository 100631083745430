/* ----------- header --------- */
.header {
  background-color: #001f3f;
  /* border-bottom: 1px solid #707070; */
  /* overflow: hidden; */
  font-size: 1.4rem;
  position: fixed;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 5;
  transition: height 0.2s;
  overflow: hidden;
}

.header__container {
  padding-right: 1rem;
  padding-left: 1rem;
}

.header__container::before {
  display: table;
  content: " ";
}

/* ----------- logo ------------------ */
.header .header__logo {
  float: left;
}

.header .header__logo .header__logo__img {
  /* display: none; */
  height: 41px;
  width: 122px;
  margin-top: 0.6rem;
}

/* .header__logomobile__img {
    display: none;
    height: 44px;
    width: 200px;
    margin-top: .6rem;
} */

/* ------------- menu icon --------------- */

.header .header__menu-main__toggler:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .header__menu-main__toggler:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .header__menu-main__toggler:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header
  .header__menu-main__toggler:checked
  ~ .menu-icon:not(.steps)
  .navicon:before,
.header
  .header__menu-main__toggler:checked
  ~ .menu-icon:not(.steps)
  .navicon:after {
  top: 0;
}

/* ----------- menu main ----------- */

.header .header__menu-main {
  /* margin-right: 3rem; */
  float: left;
}

.header__menu-main__toggler {
  color: white;
  font-size: 2rem !important;
  cursor: pointer;
  float: right;
  margin-right: 0.5rem;
  margin-top: 1.3rem;
  display: none;
}

.product__link__footer {
  list-style-type: none;
  padding: 2rem;
  width: 50%;
}

@media only screen and (min-width: 1071px) {
  .header {
    font-size: 1.4rem;
    height: auto !important;
  }

  .header__menu-main__toggler {
    display: none;
  }

  .header__menu__nav {
    display: inline !important;
    /* transition: all .5s; */
  }

  .header__menu-main__list {
    display: block;
  }

  .header__menu-main__collapse {
    float: left;
  }

  .header__menu-main__list > li {
    float: left;
  }

  .header__menu-login {
    float: right;
  }
}

@media only screen and (max-width: 900px) {
  .contact__link__footer {
    width: 50% !important;
    /* text-align: center; */
  }

  .product__link__footer {
    width: 50% !important;
  }

  .footer {
    width: 80% !important;
    margin-left: 58% !important;
  }
}

@media only screen and (max-width: 1070px) {
  /* .header__logomobile__img {
    display: inline;
}

.header__logo__img {
    display: none;
} */
  .line {
    width: 100%;
    height: 1px;
    background: #747474;
    opacity: 0.4;
    margin: auto;
  }

  .header__menu-main__list {
    height: auto;
    display: grid;
  }

  .header__menu__nav {
    float: center;
    display: grid;
    margin-top: 5.7rem;
    margin-left: 5rem;
    /* transition: all .5s; */
  }

  .header .menu-icon {
    padding: 2rem 1rem;
    margin: 0;
    cursor: pointer;
    display: inline-block;
    float: right;
    position: relative;
    user-select: none;
    margin-top: 0.7rem;
  }

  .header .menu-icon .navicon {
    background: #fff;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }

  .header .menu-icon .navicon:before,
  .header .menu-icon .navicon:after {
    background: #fff;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  .header .menu-icon .navicon:before {
    top: 5px;
  }

  .header .menu-icon .navicon:after {
    top: -5px;
  }

  .product__link__footer {
    display: none;
  }

  .contact__link__footer {
    width: 100% !important;
    padding: 0rem !important;
    text-align: center;
    /* margin-left: 50%;
transform: translate(-50%, 0%); */
  }

  .icon__footer__container {
    width: 102%;
  }

  .grid_icon {
    justify-content: center !important;
  }

  .footer {
    margin-left: 50% !important;
    transform: translate(-50%, 0%) !important;
  }
}

.header__menu-main__toggler:checked ~ .header__menu__nav {
  height: 100%;
  display: grid;
  transition: all 0.5s;
}

/* .header .header__menu-main__toggler:checked~.menu-icon .backdrop {
    display: block;
} */

.header__menu-main__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  /* margin-top: 0.4rem; */
}

.header__menu-main__link {
  display: block;
  text-align: left;
  padding: 1.8rem 1.6rem;
  text-decoration: none;
  color: white;
}

/* .collapse:not(.show){
        display: none;
    } */

/* ------ login ------- */

/* .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: black;
    opacity: 0;
    display: none;
    z-index: -1;
} */

/* ------ end header ------- */

/* ------- body ------------ */
.body_container {
  position: relative;
  top: 5.7rem;
}

/* /////////////////////// */

/* .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 2rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 6;
}

li.dropdown {
    display: inline-block;
}

.header__menu-main__link:hover~.dropdown-content {
    display: block !important;
} */

.dropdown {
  visibility: hidden;
  position: absolute;
  background-color: white;
  min-width: 2rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 6;
  list-style-type: none;
  width: 20rem;
  right: 0;
}

.dropdown_link {
  display: block;
  text-align: center;
  padding: 1.8rem 1.6rem;
  text-decoration: none;
  color: black;
}

.dropdown_link:hover,
.dropdown_link:focus-within {
  background-color: #dee0e2;
  cursor: pointer;
}

.header__menu-main__link:hover {
  /* background-color: #023b6c; */
  opacity: 0.6;
  cursor: pointer;
}

.header__dropdown__link:hover {
  /* background-color: #023b6c; */
  opacity: 0.6;
  cursor: pointer;
}

.header__dropdown__link {
  border: none;
  display: block;
  text-align: left;
  padding: 1.8rem 1.6rem;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.header__dropdown__link:hover {
  visibility: visible;
}

.ask__font__footer {
  margin-top: 3rem;
  /* background-color: #001F3F; */
  /* color: white; */
  color: black;
}

.text__link__footer {
  text-decoration: none;
  color: #515a5a;
  cursor: pointer;
}

.text__link__footer:hover {
  color: #ff851b;
}

.link__footer {
  list-style-type: none;
}

.icon__footer {
  padding: 1.6rem !important;
  margin: 0.3rem;
}

.footer {
  width: 50%;
  margin-left: 55%;
  transform: translate(-50%, 0%);
}

.contact__link__footer {
  padding: 2rem;
  width: 50%;
  /* text-align: center; */
}

.grid_icon {
  justify-content: flex-start;
}
