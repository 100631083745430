 /* img {
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    object-fit: cover;
} */


 .image-gallery-slide img {
     width: 100%;
     height: 70vh;
 }

 .img_header {
     background-size: contain;
     background-repeat: no-repeat;
     width: 100%;
     height: inherit;
     object-fit: fill;

     /* position: relative;
     width: 100%;
     padding-top: 62.5%; */

     /* display: block;
     max-width:230px;
     max-height:95px;
     width: auto;
     height: auto; */
 }

 .head {
     position: relative;
     width: 100%;
     height: 50vh;
 }

 @media only screen and (max-width: 1000px) {
    .head {
        height: 40vh;
    }

    .image-gallery-slide img {
        height: 40vh;
    }
}

 @media only screen and (max-width: 600px) {
     .head {
         height: 35vh;
     }

     .image-gallery-slide img {
         height: 35vh;
     }
 }

 @media only screen and (max-width: 400px) {
     .head {
         height: 12vh;
     }

     .image-gallery-slide img {
         height: 12vh;
     }
 }



 .head-txt {
     position: absolute;
     height: 100%;
     width: 100%;
     top: 30%;
     color: white;
     text-shadow: -0.1rem 0 black, 0 0.1rem black, 0.1rem 0 black, 0 -0.1rem black;
     font-size: 2rem;
     text-align: center;
 }



 @font-face {
     font-family: 'Prompt-Regular';
     src: url('../../../assets/font/Prompt-Regular.ttf');
 }