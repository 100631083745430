.dot,
.step1,
.step2,
.step3,
.step4 {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 10px solid #17E525;
  transform: rotate(-45deg);
}

.inputtext::placeholder {
  text-align: center;
}

/* input[type=text]::placeholder {
  font-size: 1rem;
} */

.inputtext {
  text-align: center;
}

.step1 {
  border-bottom-color: #CECECE;
  border-left-color: #CECECE;
  border-top-color: #CECECE;
}

.step2 {
  border-left-color: #CECECE;
  border-top-color: #CECECE;
}

.step3 {
  border-top-color: #CECECE;
}

/* @font-face {
    font-family: ASG;
    src: url("bangna-new.ttf"); } */

.buttonCross {
  background: url("../../assets/image/close.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 2.5rem;
  padding: 1rem;
  cursor: pointer;
}

.buttonCheck {
  background: url("../../assets/image/check.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  margin: auto;
  text-align: center;
  padding: 50px;
}

.buttonSearch {
  background: url("../../assets/image/search.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 1rem;
  cursor: pointer;
}

.btn_apply_submit {
  font-family: 'Prompt-Regular';
  width: 20% !important;
  float: right;
  margin-top: 1rem;
  margin-right: .5rem
}

@media only screen and (max-width: 700px) {
  .btn_apply_submit {
    width: 100% !important;
    margin-right: 0 !important;
  }
}

@media(max-width: 1000px) {
  .panel {
    position: relative;
    width: 100%;
    margin: auto;
    background-color: white;
    border: 0.1rem solid;
    border-radius: 1rem;
    border-color: white;
    padding: 0rem 3rem;
  }

}

@media(min-width: 1000px) {
  .panel {
    margin-top: -2rem;
    position: relative;
    width: 60%;
    margin: auto;
    border-radius: 1rem;
    border-color: white;
    padding-right: 10rem;
    padding-left: 10rem;
  }

  .panel__payment {
    width: 100% !important;
  }

  .panel__bank {
    width: 100% !important;
  }
}


@media screen and (orientation: portrait) {
  .flip {
    /* Rotate the content container */
    /* background-color: white; */
    transform: rotate(-90deg);
    /* transform-origin: left top; */
    /* Set content width to viewport height */
    /* width: 100vh; */
    /* Set content height to viewport width */
    /* height: 100%; */
    /* width: 100%; */
    /* overflow-x: hidden; */
    /* position: absolute; */
    /* top: 100%; */
    /* left: 0; */
  }
}

.test {
  display: flex !important;
}

.width100 {
  width: 100% !important;
}

.rotate-p {
  transform: rotate(45deg);
  margin: 3.3rem 0rem;
  text-align: center;
  /* font-size: 1.6rem; */
  font-weight: bold;
}

.nav-text {
  text-align: right;
  margin-top: 36px;
}

.a-text {
  color: #D3B46F;
  float: left;
}

.margintop {
  margin-top: 10px;
}

select:invalid {
  color: gray;
}

.text-orange {
  color: #FF851B;
}

.text-green {
  color: #22A86D;
}

.text-white {
  color: white;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-bold {
  font-weight: bold;
}

/* p {
  font-size: 1.4rem;
} */

.hline {
  width: 95%;
  height: 1px;
  background: #747474;
  opacity: 0.4;
  margin: auto;
}

.vline {
  width: 1px;
  height: 100%;
  background: #747474;
  opacity: 0.4;
  margin: auto;
}