
@media(min-width: 600px) {
    .branchpanel {
        margin-top: 2rem;
        margin-left: 3rem;
        margin-right: 3rem;
    }
}@media(max-width: 600px) {
    .branchpanel {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

.cd-tabs__list {
    display: flex;
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }