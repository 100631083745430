.question__container {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* font-size: 1.6rem; */
}

.question__header {
    display: flex;
    justify-content: center;
    padding: 1rem;
    color: #ff7700;
}

.question__body {
    border-top: 1rem solid #ff9600;
    border-left: 1px solid #d7d7d7;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 5px 10px #e3e3e3;
    width: 30rem;
    margin-bottom: 2rem;
    margin-left: 50%;
    transform: translate(-50%, 0%);
}

.question__body>div {
    padding: 1rem;
}

.question__body_question {
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.question__body_question>a {
    text-decoration: none;
    display: block;
    text-align: center;
    color: black;
    max-width: 50%;
    flex: 0 0 50%;
}

.question__body_question>.split {
    position: absolute;
    left: 50%;
    border-left: 1px solid #999;
    height: 100%;
    top: 0;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    /* font-size: 1.4rem; */
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #babfc5;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

label {
    display: inline-block;
    /* margin-bottom: .5rem; */
    color: #999;
}

.btn-submit {
    text-align: center;
    background-color: #ff9600;
    border-color: transparent;
    padding: 1rem;
    border-radius: .5rem;
    color: white;
    cursor: pointer;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    /* font-size: 1.6rem; */
}

input[type="text"]:focus,
input[type="password"]:focus {
    border-color: #ff9600;
}

.question__footer {
    text-align: center;
    margin: 4.5rem 0;
}

.question__question>a {
    padding-left: 1rem;
    color: #ff7700;
}

.radio_button {
    width: 50%;
}

.loader {
    border: .2rem solid #fff;
    border-radius: 50%;
    border-top: .2rem solid red;
    width: 2rem;
    height: 2rem;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
    margin-left: 45%;
    margin-bottom: .5rem;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}