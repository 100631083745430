.graph {
    margin-top: 1rem;
    /* margin-left: 1rem; */
    height: 100%;
    width: 100%;
}

@media(max-width: 1000px) {
    .panel__graph {
        position: relative;
        width: 100%;
        margin: auto;
        background-color: white;
        border: 0.1rem solid;
        border-radius: 1rem;
        border-color: white;
        padding: 0rem 2rem;
    }
}

@media(min-width: 1000px) {
    .panel__graph {
        margin-top: -2rem;
        position: relative;
        width: 70%;
        margin: auto;
        border-radius: 1rem;
        border-color: white;
        padding-right: 10rem;
        padding-left: 10rem;
    }
}