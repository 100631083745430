.bank-icon {
  width: 60px;
  height: 60px;
  padding: 5px;
}

/* , "official_name": "BANGKOK BANK PUBLIC COMPANY LTD.", "nice_name": "Bangkok Bank"  */
.bank-icon.bbl {
  background-color: #1e4598;
}
/* , "official_name": "KASIKORNBANK PUBLIC COMPANY LTD.", "nice_name": "Kasikornbank"  */
.bank-icon.kbank {
  background-color: #138f2d;
}
/* , "official_name": "THE ROYAL BANK OF SCOTLAND PLC", "nice_name": "Royal Bank of Scotland"  */
.bank-icon.rbs {
  background-color: #032952;
}
/* , "official_name": "KRUNG THAI BANK PUBLIC COMPANY LTD.", "nice_name": "Krungthai Bank"  */
.bank-icon.ktb {
  background-color: #1ba5e1;
}
/* , "official_name": "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION", "nice_name": "J.P. Morgan"  */
.bank-icon.jpm {
  background-color: #321c10;
}
/* , "official_name": "THE BANK OF TOKYO-MITSUBISHI UFJ, LTD.", "nice_name": "Bank of Tokyo-Mitsubishi UFJ"  */
.bank-icon.mufg {
  background-color: #d61323;
}
/* , "official_name": "TMB BANK PUBLIC COMPANY LIMITED.", "nice_name": "TMB Bank"  */
.bank-icon.tmb {
  background-color: #1279be;
}
/* , "official_name": "SIAM COMMERCIAL BANK PUBLIC COMPANY LTD.", "nice_name": "Siam Commercial Bank"  */
.bank-icon.scb {
  background-color: #4e2e7f;
}
/* , "official_name": "CITIBANK, N.A.", "nice_name": "Citibank"  */
.bank-icon.citi {
  background-color: #1583c7;
}
/* , "official_name": "SUMITOMO MITSUI BANKING CORPORATION", "nice_name": "Sumitomo Mitsui Banking Corporation"  */
.bank-icon.smbc {
  background-color: #a0d235;
}
/* , "official_name": "STANDARD CHARTERED BANK (THAI) PUBLIC COMPANY LIMITED", "nice_name": "Standard Chartered (Thai)"  */
.bank-icon.sc {
  background-color: #0f6ea1;
}
/* , "official_name": "CIMB THAI BANK PUPBLIC COMPANY LTD.", "nice_name": "CIMB Thai Bank"  */
.bank-icon.cimb {
  background-color: #7e2f36;
}
/* , "official_name": "UNITED OVERSEAS BANK (THAI) PUBLIC COMPANY LIMITED", "nice_name": "United Overseas Bank (Thai)"  */
.bank-icon.uob {
  background-color: #0b3979;
}
/* , "official_name": "BANK OF AYUDHYA PUBLIC COMPANY LTD.", "nice_name": "Bank of Ayudhya (Krungsri)"  */
.bank-icon.bay {
  background-color: #fec43b;
}
/* , "official_name": "MEGA INTERNATIONAL COMMERCIAL BANK PUBLIC COMPANY LIMITED", "nice_name": "Mega International Commercial Bank"  */
.bank-icon.mega {
  background-color: #815e3b;
}
/* , "official_name": "BANK OF AMERICA, NATIONAL ASSOCIATION", "nice_name": "Bank of America"  */
.bank-icon.boa {
  background-color: #e11e3c;
}
/* , "official_name": "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK", "nice_name": "Crédit Agricole"  */
.bank-icon.cacib {
  background-color: #0e765b;
}
/* , "official_name": "THE GOVERNMENT SAVINGS BANK", "nice_name": "Government Savings Bank"  */
.bank-icon.gsb {
  background-color: #eb198d;
}
/* , "official_name": "THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD.", "nice_name": "Hongkong and Shanghai Banking Corporation"  */
.bank-icon.hsbc {
  background-color: #fd0d1b;
}
/* , "official_name": "DEUTSCHE BANK AG.", "nice_name": "Deutsche Bank"  */
.bank-icon.db {
  background-color: #0522a5;
}
/* , "official_name": "THE GOVERNMENT HOUSING BANK", "nice_name": "Government Housing Bank"  */
.bank-icon.ghb {
  background-color: #f57d23;
}
/* , "official_name": "BANK FOR AGRICULTURE AND AGRICULTURAL COOPERATIVES", "nice_name": "Bank for Agriculture and Agricultural Cooperatives"  */
.bank-icon.baac {
  background-color: #4b9b1d;
}
/* , "official_name": "MIZUHO BANK, LTD.", "nice_name": "Mizuho Bank"  */
.bank-icon.mb {
  background-color: #150b78;
}
/* , "official_name": "BNP PARIBAS", "nice_name": "BNP Paribas"  */
.bank-icon.bnp {
  background-color: #14925e;
}
/* , "official_name": "THANACHART BANK PUBLIC COMPANY LTD.", "nice_name": "Thanachart Bank"  */
.bank-icon.tbank {
  background-color: #fc4f1f;
}
/* , "official_name": "ISLAMIC BANK OF THAILAND", "nice_name": "Islamic Bank of Thailand"  */
.bank-icon.ibank {
  background-color: #184615;
}
/* , "official_name": "TISCO BANK PUBLIC COMPANY LIMITED", "nice_name": "Tisco Bank"  */
.bank-icon.tisco {
  background-color: #12549f;
}
/* , "official_name": "KIATNAKIN BANK PUBLIC COMPANY LIMITED", "nice_name": "Kiatnakin Bank"  */
.bank-icon.kk {
  background-color: #199cc5;
}
/* , "official_name": "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED", "nice_name": "Industrial and Commercial Bank of China (Thai)"  */
.bank-icon.icbc {
  background-color: #c50f1c;
}
/* , "official_name": "THE THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED", "nice_name": "Thai Credit Retail Bank"  */
.bank-icon.tcrb {
  background-color: #0a4ab3;
}
/* , "official_name": "LAND AND HOUSES BANK PUBLIC COMPANY LIMITED", "nice_name": "Land and Houses Bank" */
.bank-icon.lhb {
  background-color: #6d6e71;
}
