.btndownload {
    font-family: 'Prompt-Regular';
    width: 250px !important;
    height : 50px;
    float: right;
    margin-top: 1rem;
    margin-right: .5rem;
    color: blue;    
    background-color: hsl(201, 69%, 82%);
    border-color: #ff9600;  
  }
  
.btnupload {
    font-family: 'Prompt-Regular';
    width: 250px !important;
    height : 40px;
    float: right;
    margin-top: 1rem;
    margin-right: .5rem;
    color: blue;
    background-color: hsl(201, 69%, 82%);
    border-color: #ff9600;    
  }


.text-orange {
    color: #FF851B;
  }
  
  .text-green {
    color: #22A86D;
  }
  
  .text-white {
    color: white;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-right {
    text-align: right;
  }
  
  .text-left {
    text-align: left;
  }
  
  .text-bold {
    font-weight: bold;
  }