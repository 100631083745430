*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: "Prompt-Regular" !important;
  src: url("./assets/font/Prompt-Regular.ttf");
}

.field-icon {
  float: right;
  margin-top: -2.5rem;
  margin-right: 1.2rem;
  position: relative;
  z-index: 2;
  cursor: pointer;
  color: #999;
}

input:focus {
  outline: 0;
}

.ask__font__footer {
  font-size: 1.6rem !important;
}

.ask__font__main {
  font-size: 1.6rem !important;
}

.ask__font__title {
  font-size: 1.8rem !important;
}

.p-button,
.p-card-title,
.p-toast-message,
.p-toast-title,
.p-dialog-title,
.p-dialog-content,
.p-inputnumber-input {
  font-size: 1.6rem !important;
  font-family: "Prompt-Regular" !important;
}

@font-face {
  font-family: "Prompt-Regular" !important;
  src: url("./assets/font/Prompt-Regular.ttf");
}

.p-tooltip .p-tooltip-text {
  width: 321px;
}

.p-multiselect-item {
  font-size: 1.6rem !important;
  font-family: "Prompt-Regular" !important;
  padding: 1rem;
}
