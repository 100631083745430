/* @font-face {
    font-family: 'TH Sarabun';
    src: url(../../../assets/font/THSarabunNew.ttf);
} */

.search {
    border: 1px solid transparent !important;
    width: 100%;
}

.uploadbtn {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.uploadbtn input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.input {
    width: 100%;
    /* padding-top: 0.5rem; */
}

/* input[type="text"]:focus, */
input:focus {
    border-color: #ff9600;
}

.input__element {
    /* -webkit-appearance: none; */
    /* -moz-appearance: none; */
    /* appearance: none; */
    width: 100%;
    /* min-width: 96%; */
    height: 3.5rem;
    padding: 0.5rem 0.8rem;
    border: 1px solid #ccc;
    border-radius: 7px;
    box-sizing: border-box;
}

.remove {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.setfontsize {
    font-size: 1.2rem !important;
    font-family: 'Prompt-Regular' !important;
    padding-top: .8rem;
    display: block;
    color: black;
    flex: 1;
}

.label {
    /* width: 60%; */
    padding-top: .8rem;
    display: block;
    color: black;
    flex: 1;
}

.label_text {
    padding-top: 1rem;
    width: 60%;
    /* font-size: 1.4rem; */
    /* margin-bottom: 0.8rem; */
    display: block;
    color: black;
}

.text__align__center {
    text-align: center;
}

.text__align__left {
    text-align: left;
}

.text__align__right {
    text-align: right;
}

@media only screen and (max-width: 550px) {
    .label__pos {
        width: 100%;
        /* font-size: 1.4rem; */
        display: block !important;
    }

    .icon {
        padding: 0.6rem !important;
    }
}

.label__pos {
    margin: .5rem;
    width: 100%;
    /* font-size: 1.4rem; */
    display: inherit;
}

.label__top {
    display: block;
}


.label__left {
    display: inherit;
}

.invalid {
    border: 1px solid red !important;
    /* background-color: #FDA49A; */
}

.text__invalid {
    font-size: 1.2rem;
    color: red;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker {
    font-size: 1.2rem !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
    margin: 0.5rem !important;
}

.react-datepicker-popper {
    transform: translate3d(10px, -190px, 0px) !important;
}

/* .InputElement:focus {
    outline: none;
    background-color: #ccc;
} */


/* .checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-label .checkbox-custom {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border-radius: 5px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    border: 2px solid #FFFFFF;
}


.checkbox-label input:checked ~ .checkbox-custom {
    background-color: #FFFFFF;
    border-radius: 5px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity:1;
    border: 2px solid #FFFFFF;
}


.checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid #009BFF;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity:1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}


.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity:1;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #009BFF;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
} */



/* For Ripple Effect */
/* .checkbox-label .checkbox-custom::before {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    width: 0px;
    height: 0px;
    border-radius: 5px;
    border: 2px solid #FFFFFF;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);    
}

.checkbox-label input:checked ~ .checkbox-custom::before {
    left: -3px;
    top: -3px;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    transform: scale(3);
    opacity:0;
    z-index: 999;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
} */

